<template>
  <dark-circles-template
    :dark-circles="darkCircles"
    :dark-circle-colors="darkCircleColors"
    :dark-circle-colors-visible="darkCircleColorsVisible"
    :dark-circles-childhood="darkCirclesChildhood"
    :dark-circles-childhood-visible="darkCirclesChildhoodVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DarkCirclesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/dark-circles/DarkCirclesTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes } from '@/modules/questionnaire/api/helpers';

import { DARK_CIRCLE_COLOR } from '@/modules/questionnaire/api/constants';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS_TO_RESET = ['darkCircleColors', 'darkCirclesChildhood'];

const FIELDS = [
  requiredField('darkCircles'),
  requiredArrayField('darkCircleColors'),
  requiredField('darkCirclesChildhood')
];

export default {
  name: 'DarkCircles',
  components: { DarkCirclesTemplate },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    darkCircleColorsVisible() {
      return yes(this.darkCircles);
    },
    darkCirclesChildhoodVisible() {
      return this.darkCircleColors.includes(DARK_CIRCLE_COLOR.HOLLOWED);
    }
  },
  watch: {
    darkCircles(newValue) {
      if (yes(newValue)) {
        this.scrollTo('#dark-circle-colors');

        return;
      }

      this.resetStoreFieldsByName(FIELDS_TO_RESET);
      this.showNextStep();
    },
    darkCircleColors() {
      if (this.darkCirclesChildhoodVisible) {
        this.scrollTo('#dark-circles-childhood');

        return;
      }

      this.resetStoreFieldsByName(['darkCirclesChildhood']);
    },
    darkCirclesChildhood(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'darkCircleColors') {
        return this.darkCircleColorsVisible;
      }

      if (fieldName === 'darkCirclesChildhood') {
        return this.darkCirclesChildhoodVisible;
      }

      return true;
    }
  }
};
</script>
